import TrustPilotLogo from "@ecologi/assets/icons/trustpilot-logo-black.svg";
import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { theme as ecologiTheme } from "../theme";
import { useTrustPilot } from "./TrustPilotProvider";

// Providers centering alignment
const TrustPilotWrapper = styled.div<{
  isLoaded: boolean;
  isCentralized: boolean;
}>`
  // Hide from view if not loaded - but keep in DOM tree for ref persistence
  align-items: center;
  display: ${(props) => (props.isLoaded ? "flex" : "none")};
  justify-content: ${(props) =>
    props.isCentralized ? "center" : "flex-start"};

  // Fixes clipping
  overflow: hidden;
`;

// Providers relative point for overlay image
const TrustPilotTrustBoxWrapper = styled.a<{ useCenteredImage: boolean }>`
  position: relative;

  .trustpilot-widget {
    padding-top: ${(props) => (props.useCenteredImage ? "26px" : "")};
  }

  // max-width on the iframe stops component overhanging on small mobiles
  iframe {
    max-width: 100% !important;
  }
`;

const TrustPilotCenteredLogo = styled.div<{ backgroundColor: string }>`
  position: absolute;
  z-index: 5;
  top: 0;
  margin: 0 38px 0 23px;
  background-color: ${(props) => props.backgroundColor};
`;

export const TrustPilotTrustBox = ({
  theme = "light",
  templateId,
  onLoaded,
  onErrored,
  useCenteredImage = false,
  backgroundColor = ecologiTheme.colors.transparent,
  width = "300px",
  height = "130px",
  componentStyles = {
    isWrapperCentralized: true,
  },
}: {
  theme: "light" | "dark";
  templateId: string;
  backgroundColor?: string;
  useCenteredImage?: boolean;
  onLoaded?: () => void;
  onErrored?: () => void;
  width?: string;
  height?: string;
  componentStyles?: {
    isWrapperCentralized?: boolean;
  };
}) => {
  const ref = useRef(null);
  const {
    isLoaded: isScriptLoaded,
    isErrored,
    businessUnitId,
  } = useTrustPilot();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // 1st script load will do a search for these components, but elements
    // loaded after the fact will need to have this called to mount to TP box
    if (isScriptLoaded) {
      window["Trustpilot"]?.loadFromElement(ref.current, true);
      setIsLoaded(true);
      onLoaded?.();
    }
  }, [isScriptLoaded]);

  useEffect(() => {
    if (isErrored) {
      onErrored?.();
    }
  }, [isErrored]);

  return (
    <TrustPilotWrapper
      // Only show once loaded & mounted
      isLoaded={isLoaded}
      isCentralized={Boolean(componentStyles?.isWrapperCentralized)}
    >
      <TrustPilotTrustBoxWrapper
        href="https://uk.trustpilot.com/review/ecologi.com"
        target="_blank"
        rel="noopener"
        useCenteredImage={useCenteredImage}
      >
        {/*
          Requirement to have TrustPilot image centered, can't do CSS because element
          is in an iframe - however we can do this by adding an image z-indexed above
          the iframe - with a background colour the same as the backdrop,
          and then just obscure the actual iframe logo
        */}
        {isLoaded && useCenteredImage && (
          <TrustPilotCenteredLogo backgroundColor={backgroundColor}>
            <TrustPilotLogo />
          </TrustPilotCenteredLogo>
        )}

        <div
          ref={ref}
          className="trustpilot-widget"
          data-template-id={templateId}
          data-businessunit-id={businessUnitId}
          data-locale="en-GB"
          data-style-height={height}
          data-style-width={width}
          data-theme={theme}
        ></div>
      </TrustPilotTrustBoxWrapper>
    </TrustPilotWrapper>
  );
};
