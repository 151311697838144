import styled from "@emotion/styled";

type HeroContainerBaseProps = {
  bgImage: string;
};

export const HeroContainerBase = styled.div<HeroContainerBaseProps>`
  align-items: center;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;
