import React from "react";
import styled from "@emotion/styled";

type Props = {
  numEmployees: string;
  setNumEmployees: (num: string) => void;
};

const Input = styled.input`
  background: ${(props) => props.theme.colors.snowdrop};
  border: none;
  border-top: 5px solid ${(props) => props.theme.colors.mistDarker};
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
  outline: none;
  padding: 10px;
  text-align: center;
  width: 140px;

  // Remove the little arrow things
  -moz-appearance: textfield; /*For FireFox*/
  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-top-color: ${(props) => props.theme.colors.darkGrey};
  }
`;

export const EmployeeInput: React.FC<Props> = (props) => {
  const { numEmployees, setNumEmployees } = props;
  // const isSmallScreen = useMedia("(max-width: 480px)");

  return (
    <Input
      // autoFocus={!isSmallScreen}
      data-testid="numEmployees"
      type="number"
      id="numEmployees"
      name="numEmployees"
      value={numEmployees}
      max={99999}
      min={0}
      onBlur={() => {
        if (numEmployees === "") {
          setNumEmployees("30");
        }
      }}
      onChange={(e) => {
        let val = e.currentTarget.value;
        val = val.replace(/[^\d]/g, "");
        let num = parseInt(val);
        if (!isNaN(num)) {
          num = num > 99999 ? 99999 : num;
          num = num < 0 ? 0 : num;
          setNumEmployees(num.toString());
          return;
        }
        setNumEmployees("");
      }}
    />
  );
};
