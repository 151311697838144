import React from "react";
import { Head } from "../../components/head";
import { getMetaData } from "../../lib";
import { WidgetBuilder } from "..";

export const WidgetPage = ({ footer, header, background = true, ...props }) => {
  const { titleText, descText, socialImageUrl, socialTitle, noIndex } =
    getMetaData(props.data);

  return (
    <div style={{ ...(background ? { background: "white" } : {}) }}>
      <Head
        title={titleText}
        socialTitle={socialTitle}
        description={descText}
        imageUrl={socialImageUrl}
        noIndex={noIndex}
      />
      {header}
      <WidgetBuilder {...props} />
      {footer}
    </div>
  );
};
