import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Wrapper as BaseWrapper } from "../Wrapper";
import { excludePropsFromForwarding } from "../utils";

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 25px;
  text-align: center;

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 40px;
  }
`;

const Subtitle = styled.h2`
  font-size: 20px;
  line-height: 1.28;
  text-align: center;

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 24px;
  }
`;

type ContainerProps = {
  isLight?: boolean;
};

const Container = styled(
  BaseWrapper,
  excludePropsFromForwarding("isLight")
)<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isLight &&
    css`
      color: ${props.theme.colors.snowdrop};
    `}
`;

export type ContentProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode | React.ReactNode[];
} & ContainerProps;

export function Content({ title, subtitle, children, ...props }: ContentProps) {
  return (
    <Container {...props}>
      {children}
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
}
