import {
  HeroInfoPage,
  HeroInfoPageProps,
} from "@ecologi/react-components/src/HeroInfoPage";

type Props = {
  data: HeroInfoPageProps;
};

export default function HeroWidget(props: Props) {
  return <HeroInfoPage {...props.data} />;
}
