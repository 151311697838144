import React from "react";

import classnames from "classnames";
import styles from "./ThreeColExplainer.module.scss";

type Props = {
  columnData: any;
  columnKeys: string[];
};

export const ThreeColExplainer: React.FC<Props> = (props) => {
  const { columnData, columnKeys } = props;

  return (
    <ul className={styles.leaderCols}>
      {columnData.map((d: any, idx: number) => (
        <li
          key={idx}
          className={classnames(
            styles.leaderCol,
            styles[`leaderCol${columnKeys[idx]}`]
          )}
        >
          <span className={styles.leaderColNumber}>{idx + 1}</span>
          <h3 className={styles.leaderColTitle}>{d.heading}</h3>
          <p className={styles.leaderColText}>{d.body}</p>
        </li>
      ))}
    </ul>
  );
};
