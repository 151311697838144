import React from "react";
import dynamic from "next/dynamic";
import Hero from "./individual-widgets/hero";
import InfoPageHero from "./individual-widgets/info-page-hero";
import HomePageHero from "./individual-widgets/home-page-hero";
import VideoHero from "./individual-widgets/video-hero";
import BusinessHeroV2 from "./individual-widgets/business-page-hero";

// Don't dynamic import hero components because it will increase element render delay for our LCP
const HIGH_PRIORITY_WIDGETS = {
  hero: {
    Component: Hero,
  },
  info_page_hero: {
    Component: InfoPageHero,
  },
  homepage_hero: {
    Component: HomePageHero,
  },
  video_hero: {
    Component: VideoHero,
  },
  business_hero_v2: {
    Component: BusinessHeroV2,
  },
};

// These widgets will appear below the fold so can be dynamic imported
const LOW_PRIORITY_WIDGETS = {
  featured_projects: {
    Component: dynamic(() => import("./individual-widgets/featured-projects")),
  },
  impact_categories: {
    Component: dynamic(() => import("./individual-widgets/impact-cards"), {
      ssr: false,
    }),
  },
  partners_showcase: {
    Component: dynamic(() => import("./individual-widgets/partners-showcase")),
  },
  faqs: {
    Component: dynamic(() => import("./individual-widgets/faqs")),
  },
  testimonials: {
    Component: dynamic(() => import("./individual-widgets/testimonials")),
  },
  features_showcase: {
    Component: dynamic(() => import("./individual-widgets/features-showcase")),
  },
  subscriber_features: {
    Component: dynamic(() => import("./individual-widgets/features-section")),
  },
  background_image: {
    Component: dynamic(() => import("./individual-widgets/background-image")),
  },
  media_features_showcase: {
    Component: dynamic(() => import("./individual-widgets/featured-in")),
  },
  local_reforestation_project_info: {
    Component: dynamic(
      () => import("./individual-widgets/local-reforestation-info")
    ),
  },
  sign_up_cta: {
    Component: dynamic(() => import("./individual-widgets/sign-up-cta")),
  },
  business_sign_up_cta: {
    Component: dynamic(
      () => import("./individual-widgets/business-sign-up-cta")
    ),
  },
  carousel: {
    Component: dynamic(() => import("./individual-widgets/carousel")),
  },
  text: {
    Component: dynamic(() => import("./individual-widgets/text")),
  },
  text_v2: {
    Component: dynamic(() => import("./individual-widgets/text-v2")),
  },
  subheading: {
    Component: dynamic(() => import("./individual-widgets/subheading")),
  },
  how_does_offset_earth_work: {
    Component: dynamic(
      () => import("./individual-widgets/how-does-ecologi-work")
    ),
  },
  two_panel_box: {
    Component: dynamic(() => import("./individual-widgets/two-panel-box")),
  },
  sticky_bar: {
    Component: dynamic(() => import("./individual-widgets/sticky-bar")),
  },
  impact_green_bar: {
    Component: dynamic(() => import("./individual-widgets/impact-green-bar")),
  },
  text_panel_with_image_background: {
    Component: dynamic(
      () => import("./individual-widgets/text-panel-with-image-background")
    ),
  },
  transparency: {
    Component: dynamic(() => import("./individual-widgets/transparency")),
  },
  full_width_image: {
    Component: dynamic(() => import("./individual-widgets/full-width-image")),
  },
  team: {
    Component: dynamic(() => import("./individual-widgets/team")),
  },
  business_testimonials: {
    Component: dynamic(
      () => import("./individual-widgets/business-testimonials")
    ),
  },
  view_more: {
    Component: dynamic(() => import("./individual-widgets/view-more")),
  },
  map: {
    Component: dynamic(() => import("./individual-widgets/map")),
  },
  text_in_a_coloured_panel: {
    Component: dynamic(
      () => import("./individual-widgets/text-in-coloured-panel")
    ),
  },
  gallery: {
    Component: dynamic(() => import("./individual-widgets/gallery")),
  },
  sustainable_development_goals: {
    Component: dynamic(
      () => import("./individual-widgets/sustainability-development-goals")
    ),
  },
  rows_of_alternating_copy_and_image: {
    Component: dynamic(
      () => import("./individual-widgets/row-alternating-copy-and-images")
    ),
  },
  rows_of_alternating_image_and_copy: {
    Component: dynamic(
      () => import("./individual-widgets/rows-of-alternating-images-and-copy")
    ),
  },
  rows_of_alternating_graphic_blocks: {
    Component: dynamic(
      () => import("./individual-widgets/rows-of-graphic-blocks")
    ),
  },
  content_and_cards: {
    Component: dynamic(() => import("./individual-widgets/content-and-cards")),
  },
  content_cards: {
    Component: dynamic(() => import("./individual-widgets/content-cards")),
  },
  business_impact_options: {
    Component: dynamic(
      () => import("./individual-widgets/business-impact-options")
    ),
  },
  business_impact_options_v2: {
    Component: dynamic(
      () => import("./individual-widgets/business-impact-options")
    ),
  },
  card_grid: {
    Component: dynamic(() => import("./individual-widgets/card-grid")),
  },
  lead_gen_form: {
    Component: dynamic(
      () => import("./individual-widgets/lead-generation-form"),
      { ssr: false }
    ),
  },
  data_table: {
    Component: dynamic(() => import("./individual-widgets/data-table")),
  },
  numbered_list: {
    Component: dynamic(() => import("./individual-widgets/numbered-list")),
  },
  crowdfund_pre_reg_form: {
    Component: dynamic(
      () => import("./individual-widgets/crowdfund-pre-reg-form")
    ),
  },
  crowdfund_header: {
    Component: dynamic(() => import("./individual-widgets/crowdfund-header")),
  },
  video_banner: {
    Component: dynamic(() => import("./individual-widgets/video-banner")),
  },
  banner_with_mobile: {
    Component: dynamic(() => import("./individual-widgets/banner-with-mobile")),
  },
  business_size: {
    Component: dynamic(() => import("./individual-widgets/business-size")),
  },
  mascot_cta: {
    Component: dynamic(() => import("./individual-widgets/mascot-cta")),
  },
  spacer: {
    Component: dynamic(() => import("./individual-widgets/spacer")),
  },
  logo_display_section: {
    Component: dynamic(
      () => import("./individual-widgets/logo-display-section")
    ),
  },
  big_image_section: {
    Component: dynamic(() => import("./individual-widgets/big-image-section")),
  },
  floating_text_banner: {
    Component: dynamic(() => import("./individual-widgets/callout-section")),
  },
  hills_with_impact_stats: {
    Component: dynamic(
      () => import("./individual-widgets/hills-with-impact-stats")
    ),
  },
  video_and_copy: {
    Component: dynamic(() => import("./individual-widgets/video-and-copy")),
  },
};

/**
 * Uses data from the Wordpress API to determine which widgets (i.e. React components) to
 * render
 */
export const WidgetBuilder = ({ data, ...extraData }: any) => (
  <>
    {data.acf.widgets.map((widget: any, idx: number) => {
      const key = `${idx}-${data.id}`;
      const props = { key, data: widget, ...extraData };
      const allWidgets = { ...HIGH_PRIORITY_WIDGETS, ...LOW_PRIORITY_WIDGETS };
      const widgetComponent = allWidgets[widget.acf_fc_layout];

      if (widgetComponent) {
        const { Component } = widgetComponent;
        return <Component {...props} />;
      }

      return null;
    })}
  </>
);
