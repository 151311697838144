import { useQuery } from "react-query";
import { TotalSubscriptions } from "@ecologi/shared";
import { APIError, getTotalSubscriptions } from "../api";

export function useTotalSubscriptions() {
  return useQuery<TotalSubscriptions, APIError>(
    useTotalSubscriptions.queryKey,
    getTotalSubscriptions
  );
}

useTotalSubscriptions.queryKey = "total-subscriptions";
