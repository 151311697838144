import React from "react";
import styled from "@emotion/styled";
import { Wrapper } from "../Wrapper";
import { Section as SectionBase, BackgroundVariantProps } from "../Section";
import { HeroContainerBase } from "../Hero/Container";
import { Typography } from "../Typography";

const HeroImage = styled(HeroContainerBase)`
  min-height: 320px;

  @media ${(props) => props.theme.bp.tabletUp} {
    min-height: 480px;
  }
`;

const TitleWrapper = styled(Wrapper)`
  margin-bottom: 38px;
`;

const SubtitleWrapper = styled(Wrapper)`
  margin: 56px auto;

  p {
    margin-bottom: 36px;

    &::last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Body = styled(Typography)`
  text-align: center;
`;

const Section = styled(SectionBase)`
  padding-bottom: 0;
  padding-top: 10px;
`;

export type HeroInfoPageProps = {
  image: string;
  title: string;
  subtitle: string;
  body: string;
} & BackgroundVariantProps;

export function HeroInfoPage({
  title,
  subtitle,
  image,
  body,
  ...props
}: HeroInfoPageProps) {
  return (
    <Section {...props} isLarge>
      <TitleWrapper>
        <SectionBase.Title use="featureTitle" as="h1">
          {title}
        </SectionBase.Title>
      </TitleWrapper>
      <HeroImage bgImage={image} />
      <SubtitleWrapper>
        <SectionBase.Subtitle
          use="subtitle2"
          as="h2"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        <Body use="body1">{body}</Body>
      </SubtitleWrapper>
    </Section>
  );
}
