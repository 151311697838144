import {
  b2bImpactStats,
  Country,
  ImpactPeriod,
  veryHighImpactCountries,
} from "@ecologi/shared";

type CalcImpactStats = {
  countryCode: Country;
  numOfEmployees: number;
  period?: ImpactPeriod;
  tier?: keyof typeof b2bImpactStats.high & keyof typeof b2bImpactStats.medium;
};

export const calcImpact = ({
  countryCode,
  numOfEmployees,
  period = "year",
  tier = "tier3",
}: CalcImpactStats) => {
  const isEmpty = isNaN(numOfEmployees);
  let trees = 0;
  let tonnes = 0;

  const b2bVeryHighImpact = b2bImpactStats.high[tier];
  const b2bHighImpact = b2bImpactStats.medium[tier];

  if (!isEmpty) {
    const isVeryHighImpactCountry =
      veryHighImpactCountries.includes(countryCode);
    const noOfTrees = isVeryHighImpactCountry
      ? b2bVeryHighImpact.trees
      : b2bHighImpact.trees;
    const noOfTonnes = isVeryHighImpactCountry
      ? b2bVeryHighImpact.carbonAnnual
      : b2bHighImpact.carbonAnnual;
    trees = numOfEmployees * noOfTrees * (period === "year" ? 12 : 1);
    tonnes = (numOfEmployees * noOfTonnes) / (period === "month" ? 12 : 1);
  }

  return { trees, tonnes };
};
