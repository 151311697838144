type ImgixOptions = {
  crop?: boolean;
  faces?: boolean;
};

const defaultOptions = {
  crop: false,
  faces: false,
} as ImgixOptions;

/**
 * @deprecated We should move away from Imgix, and instead make greater use of the Next
 * Image Optimization API.
 *
 * This function has been misused in places where it has no effect (likely due to its
 * generic name). It only resizes images which are hosted on our CMS.
 */
export const getResizedImageURL = (
  imageURL: string | null,
  width: number,
  height?: number | null,
  options?: ImgixOptions
) => {
  if (!imageURL) {
    return null;
  }
  const _options = options || defaultOptions;
  const [prefix, imagePath] = imageURL.split("offset.earth");
  if (prefix.includes("cms-assets")) {
    let URL = `https://offsetearth.imgix.net${imagePath}?w=${width}&auto=format`;
    URL = height ? URL + `&h=${height}` : URL;
    URL = _options.crop ? URL + `&fit=crop` : URL;
    URL = _options.faces ? URL + `&crop=faces` : URL;
    return URL;
  }

  return imageURL;
};
