import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { EcologiTheme } from "../theme";
import { rotatingTurbines } from "../theme/rotatingTurbines";

export const PageWrapper = styled.div`
  background: ${(props) => props.theme.colors.charcoal};
  clip-path: inset(0);
  height: 100%;
  min-height: 550px;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
  top: 0;
  z-index: 9;

  @media ${(props) => props.theme.bp.tabletUp} {
    height: 94vh;
  }
`;

export const VideoBgOverlay = styled.div`
  &::after {
    background: ${(props) => props.theme.colors.charcoal};
    bottom: 0;
    content: "";
    display: block;
    min-height: 100%;
    min-width: 100%;
    opacity: 0.5;
    position: absolute;
    right: 0;
  }
`;

export const VideoBg = styled.video`
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @media ${(props) => props.theme.bp.tabletUp} {
    min-height: 94vh;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

const heroTextBaseStyles = (props: { theme: EcologiTheme }) => css`
  color: ${props.theme.colors.snowdrop};
  font-weight: 300;
  line-height: 1.2;
  padding: 30px 0;
  text-align: center;

  strong {
    font-weight: bold;
  }

  br {
    display: none;
  }

  @media ${props.theme.bp.mobileUp} {
    br {
      display: inline-block;
    }
  }
`;

export const CtaSubtitle = styled.h3`
  ${heroTextBaseStyles};
`;

export const HeroTitle = styled.h1`
  ${heroTextBaseStyles};
  font-weight: 400;
  font-size: 40px;

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 50px;
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    font-size: 60px;
  }
`;

export const HeroSubtitle = styled.h2`
  ${heroTextBaseStyles};
  font-size: 22px;
  line-height: 1.3;
  margin-bottom: 14px;
  padding-top: 0;

  @media ${(props) => props.theme.bp.desktopUp} {
    font-size: 26px;
  }
`;

export const HeroPriceInfo = styled.p<{ hasPaddingTop: boolean }>`
  ${heroTextBaseStyles};
  font-size: 18px;
  padding-top: ${(props) => (props.hasPaddingTop ? "40px" : "0")};
`;

export const Form = styled.div`
  padding-top: 50px;
  text-align: center;
`;

export const InputLabel = styled.label`
  color: ${(props) => props.theme.colors.charcoal};
  display: block;
  font-size: 38px;
  font-weight: bold;
  line-height: 1.2;
  padding: 0 0 24px;

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 46px;
  }
`;

export const ImpactLabel = styled.p`
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 40px;
  opacity: 0.7;
`;

export const CtaNumbers = styled.div`
  margin-bottom: 25px;

  @media ${({ theme }) => theme.bp.tabletUp} {
    margin-bottom: 100px;
  }

  @media only screen and (min-width: 739px) {
    display: flex;
  }

  @media only screen and (min-width: 930px) {
    padding: 0 40px;
  }
`;

export const CtaNumberBlock = styled.div<{ isCarbon?: boolean }>`
  background: ${(props) => props.theme.colors.mushroomLighter};
  padding: 30px 30px 30px 134px;
  margin: 0 10px 20px;
  position: relative;

  @media only screen and (min-width: 739px) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 60px);
  }

  &::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg id='tree_icon' data-name='tree icon' xmlns='http://www.w3.org/2000/svg' width='86' height='77.78'%3E%3Cdefs%3E%3Cstyle%3E.cls-2%7Bfill-rule:evenodd;fill:%2328c382%7D%3C/style%3E%3C/defs%3E%3Cpath id='Ellipse_522_copy_3' data-name='Ellipse 522 copy 3' d='M2949.08 3682.63c20.85 0 37.75 2.04 37.75 4.56s-16.9 4.56-37.75 4.56-37.75-2.04-37.75-4.56 16.9-4.56 37.75-4.56z' transform='translate(-2905 -3613.97)' fill-rule='evenodd' fill='%23222' opacity='.23'/%3E%3Cpath id='Shape_578_copy_7' data-name='Shape 578 copy 7' class='cls-2' d='M2937.66 3647.69s-10.32 1.05-18.29-11.92-14.36-13.75-14.36-13.75 27.3-13.2 37.35 2.38c10.36 16.06.8 22.28-4.7 23.29z' transform='translate(-2905 -3613.97)'/%3E%3Cpath id='Shape_578_copy_7-2' data-name='Shape 578 copy 7' class='cls-2' d='M2965.87 3639.91s8.81-.02 14.3-11.11 10.82-12.26 10.82-12.26-24.27-8.31-31.28 5.05c-7.23 13.77 1.43 17.97 6.16 18.32z' transform='translate(-2905 -3613.97)'/%3E%3Cpath id='Shape_586_copy_2' data-name='Shape 586 copy 2' d='M2945.76 3686.19s2.93 1.36 4.81.51 1.92-29.12 4.25-35.05 16.58-26.54 24.84-30.56c0 0 .76-2.14-2.13-1.98-5.51.31-15.59 13.92-21.34 21.56a54.9 54.9 0 00-5.45 9.45c.97-1.1-15.77-20.72-25-23.6-5.31-1.66-.58 2.77-.58 2.77s22.25 17.15 22.98 28.67-3.39 26.91-2.38 28.23z' transform='translate(-2905 -3613.97)' fill='%230c9e77' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px;
    height: 75px;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%) scaleX(-1);

    @media only screen and (max-width: 364px) {
      width: 60px;
      height: 50px;
      left: 20px;
    }

    ${(props) =>
      props.isCarbon &&
      css`
        background-image: ${rotatingTurbines};
        height: 160px;
        width: 110px;
        left: 20px;
        transform: translateY(-38%); // no inversion

        @media only screen and (max-width: 364px) {
          height: 130px;
          width: 80px;
        }
      `}
  }
  // To handle massive employee numbers
  @media only screen and (max-width: 364px) {
    padding-left: 110px;
  }

  @media only screen and (max-width: 340px) {
    padding: 30px;

    &::before {
      display: none;
    }
  }

  @media only screen and (min-width: 739px) {
    flex: 1 0 calc(50% - 60px);
  }
`;

export const CtaNumber = styled.p`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.3;

  @media ${(props) => props.theme.bp.mobileUp} {
    font-size: 40px;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 46px;
  }
`;

export const CtaNumberType = styled.h4`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  position: relative;
  top: -5px;

  @media only screen and (min-width: 835px) {
    font-size: 30px;
    font-weight: 300;
  }
`;

export const CtaNumberEvery = styled.p`
  font-size: 17px;
  font-weight: 300;
  line-height: 1.3;
`;

export const MountainsBackground = styled.div<{
  isBlock?: boolean;
  isGradientBg?: boolean;
}>`
  position: relative;

  ${(props) =>
    props.isGradientBg &&
    css`
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(232, 228, 223, 1) 60%,
        rgba(134, 199, 217, 1) 100%
      );
      padding-top: 32px;
    `}

  &::before {
    background-image: url(/images/app/mountains-background.svg);
    background-repeat: repeat-x;
    background-size: 1000px;
    background-position: center;
    content: "";
    display: block;
    height: 200px;
    width: 100%;
  }

  ${(props) =>
    !props.isBlock &&
    css`
      top: 1px; // To offset the 1px on .hillsContent

      &::after {
        background-color: rgb(10, 97, 72);
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='1600.039' height='126.834' viewBox='0 0 1601 126.91'%3E%3Cpath data-name='cream hill copy' d='M0 7.91s14.9-1.56 292 4 479.24 82.06 635 48 276.72-65.76 460-59c191.33 7.06 214 7 214 7v119H0v-119z' fill='%23e9e4dd' fill-rule='evenodd'/%3E%3C/svg%3E");
        background-repeat: repeat-x;
        background-size: 1000px;
        bottom: 0;
        content: "";
        height: 60px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    `}
`;

export const HillsContent = styled.div`
  background: ${(props) => props.theme.colors.forest};
  position: relative;
  top: -1px; // remove weird border on bottom of svg
  padding: 40px 20px 60px;

  @media ${(props) => props.theme.bp.tabletUp} {
    padding-top: 20px;
    padding-bottom: 150px;
  }
`;

export const CtaTitle = styled.h3`
  ${heroTextBaseStyles};
  font-size: 30px;
  font-weight: bold;
  position: relative;
  top: -50px;

  @media ${(props) => props.theme.bp.mobileUp} {
    font-size: 32px;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 42px;
    top: 0;
  }
`;

export const Leader = styled.section`
  background: ${(props) => props.theme.colors.mushroom};
  padding: 10px 0 40px;

  @media ${(props) => props.theme.bp.mobileUp} {
    padding: 10px 0 100px;
  }
`;

export const LeaderButtons = styled.div`
  text-align: center;

  p {
    padding-top: 26px;
  }
`;
