import React from "react";
import styled from "@emotion/styled";

import { HeroIcon as Icon, HeroIconTypes } from "../HeroIcon";
import { Content, ContentProps } from "./Content";
import { HeroContainerBase } from "./Container";

type ContainerProps = {
  bgImage: string;
};

const Container = styled(HeroContainerBase)<ContainerProps>`
  min-height: 540px;

  @media ${(props) => props.theme.bp.tabletUp} {
    min-height: 640px;
  }
`;

export type HeroVariantProps = {
  image: string;
  icon?: HeroIconTypes;
} & ContentProps;

export const Hero = ({ icon, image, ...props }: HeroVariantProps) => {
  return (
    <Container bgImage={image} {...props}>
      <Content isLight {...props}>
        {icon && <Icon icon={icon} isLight />}
      </Content>
    </Container>
  );
};
