export const getMetaData = (data: any) => {
  const titleText = data.acf.meta_title || data.title.rendered || "";
  const descText = data.acf.sell || "";
  const socialImageUrl =
    (data.acf.images && data.acf.images[0] && data.acf.images[0].sizes.large) ||
    "";
  const socialTitle = data.acf.social_title_override || "";
  const noIndex = data?.acf?.meta_no_index || false;

  return { titleText, descText, socialImageUrl, socialTitle, noIndex };
};
