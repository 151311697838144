import React from "react";
import { VideoHero } from "@ecologi/react-components/src/VideoHero";
import { TotalSubscriptions } from "@ecologi/shared";
import { useQuery } from "react-query";

import {
  APIError,
  getTotalSubscriptions,
  useTotalSubscriptions,
} from "../../lib";
import { useRouter } from "next/router";
import { useStore } from "../../store";

export default function VideoHeroWidget({ data }: any) {
  const totals = useQuery<TotalSubscriptions, APIError>(
    useTotalSubscriptions.queryKey,
    getTotalSubscriptions,
    {
      enabled: data.is_business_data_visible,
      refetchOnWindowFocus: false,
    }
  );

  let numBusinesses = totals.data?.totalBusiness;
  if (numBusinesses) {
    numBusinesses += totals.data?.totalImpactOnly;
  }
  const router = useRouter();
  const { user } = useStore();

  return (
    <VideoHero
      title={data.title.replace("<p>", "").replace("</p>", "")}
      subtitle={data.subtitle}
      videoSrc={data.video_src}
      backgroundImageSrc={data.background_image_src}
      buttons={data.buttons}
      isBusinessDataVisible={data.is_business_data_visible}
      hasLargerTitle={data.has_larger_title}
      numBusinesses={numBusinesses}
      slug={router?.query?.slug}
      user={user}
    />
  );
}
