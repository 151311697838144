import React from "react";
import styled from "@emotion/styled";
import { Wrapper } from "../Wrapper";
import { HeroIcon as Icon, HeroIconTypes } from "../HeroIcon";
import { Content, ContentProps } from "./Content";
import {
  SectionBase,
  backgroundVariantExtention,
  BackgroundVariantProps,
} from "../Section";
import { HeroContainerBase } from "./Container";
import { excludePropsFromForwarding } from "../utils";

const HeroImage = styled(HeroContainerBase)`
  min-height: 320px;

  @media ${(props) => props.theme.bp.tabletUp} {
    min-height: 480px;
  }
`;

const ContentWrapper = styled(
  Wrapper,
  excludePropsFromForwarding("backgroundVariant")
)<BackgroundVariantProps>`
  ${backgroundVariantExtention}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 95%;
  color: ${(props) => props.theme.colors.charcoal};

  @media ${(props) => props.theme.bp.tabletUp} {
    max-width: 85%;
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    max-width: ${(props) => props.theme.variables.extraLargeMaxWidth};
  }

  margin-top: -127px;
  background-color: ${(props) => props.theme.colors.snowdrop};
  padding: 28px 22px 0px 22px;
`;

type HeroVariantProps = {
  image: string;
  icon?: HeroIconTypes;
} & ContentProps &
  BackgroundVariantProps;

export const HeroOffsetText = ({
  image,
  icon,
  backgroundVariant,
  ...props
}: HeroVariantProps) => {
  return (
    <SectionBase backgroundVariant={backgroundVariant}>
      <HeroImage bgImage={image} />
      <ContentWrapper isExtraLarge backgroundVariant={backgroundVariant}>
        <Content {...props}>{icon && <Icon icon={icon} />}</Content>
      </ContentWrapper>
    </SectionBase>
  );
};
