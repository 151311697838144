import React, { Fragment, useMemo, useState } from "react";
import { isChristmasPeriod as checkIsChristmasPeriod } from "@ecologi/shared";
import Image from "next/legacy/image";
import Snowfall from "react-snowfall";
// import { VideoCircle, VideoPlayerModal } from "..";
import { Button } from "../Button";
import { theme } from "../theme";
import { ThreeColExplainer } from "../ThreeColExplainer";
import { TrustPilotTrustBox } from "../TrustPilotTrustBox";
import { Wrapper } from "../Wrapper";
import {
  Hero,
  HeroContent,
  HeroCta,
  HeroCtaBusinessLink,
  HeroSubtext,
  HeroText,
  Leader,
  LeaderButtons,
  LeaderCta,
  LeaderDivider,
  LeaderTrustpilotLogoLink,
  LeaderTrustpilotText,
} from "./components";

type Explainer = {
  heading: string;
  body: string;
};

type Props = {
  heroText: string;
  heroSubtext: string;
  heroCTAText: string;
  onClickBusinessLink: any;
  onClickCTA: any;
  explainer: Explainer[];
  leaderText: string;
};

// const VideoCircleWrapper = styled.div`
//   position: relative;
//   z-index: 10;
//   margin-bottom: 115px;
// `;

export const HomePageHero = (props: Props) => {
  // const [isGTPVideoModalOpen, setIsGTPVideoModalOpen] = useState(false);
  const [useFallbackTrustPilot, setUseFallbackTrustPilot] = useState(true);
  const isChristmasPeriod = useMemo(checkIsChristmasPeriod, []);

  return (
    <Fragment>
      {/* George the Poet takeover ------------------------------------- */}
      {/* Here because of z-indexing with the header */}
      {/* <VideoPlayerModal
        isOpen={isGTPVideoModalOpen}
        onClose={() => setIsGTPVideoModalOpen(false)}
        video={{
          autoplay: true,
          assetSrc:
            "https://stream.mux.com/6dcFIKCtpC01Bi7VbK02E8FJEUQ1i3WHXTJqACZ1kesgw.m3u8",
        }}
      /> */}
      {/* George the Poet takeover ------------------------------------- */}

      <Hero>
        <HeroContent>
          <HeroText>{props.heroText} </HeroText>
          <HeroSubtext>{props.heroSubtext}</HeroSubtext>
          <HeroCta>
            <Button
              primary
              text={props.heroCTAText}
              onClick={props.onClickCTA}
            />
            <br />
            <HeroCtaBusinessLink
              role="button"
              onClick={props.onClickBusinessLink}
            >
              Looking for Ecologi for Business?
            </HeroCtaBusinessLink>
          </HeroCta>

          {/* George the Poet takeover ------------------------------------- */}
          {/* <VideoCircleWrapper>
            <VideoCircle
              title="George the Poet x Ecologi"
              previewSrc={
                "https://stream.mux.com/FPM1M2wTWt1R5evfUi006um8Jk1RC7EQ6E501hPMq00v01Y.m3u8"
              }
              onClick={() => setIsGTPVideoModalOpen(true)}
            />
          </VideoCircleWrapper> */}
          {/* George the Poet takeover ------------------------------------- */}

          {/* <HeroScrollButton
            to="leader"
            smooth
            offset={isSmallScreen ? 0 : -180}
            duration={800}
          /> */}
        </HeroContent>
      </Hero>

      {isChristmasPeriod && <Snowfall />}

      <Leader id="leader">
        <Wrapper isExtraLarge>
          <ThreeColExplainer
            columnData={props.explainer}
            columnKeys={["List", "Trees", "Turbines"]}
          />
          <LeaderCta
            dangerouslySetInnerHTML={{
              __html: props.leaderText,
            }}
          />
          <LeaderButtons>
            <Button
              primary
              text={props.heroCTAText}
              onClick={props.onClickCTA}
            />
            <LeaderDivider />

            {/* Will not be visible until loaded */}
            <TrustPilotTrustBox
              onLoaded={() => setUseFallbackTrustPilot(false)}
              onErrored={() => setUseFallbackTrustPilot(true)}
              useCenteredImage
              theme="light"
              templateId="53aa8807dec7e10d38f59f32"
              backgroundColor={theme.colors.mushroom}
            />

            {/* Fallback if not yet loaded - case of Adblockers / script failed to load */}
            {useFallbackTrustPilot && (
              <>
                <LeaderTrustpilotLogoLink
                  href="https://uk.trustpilot.com/review/ecologi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="View our Trustpilot reviews"
                >
                  <Image
                    src="/images/app/trustpilot.svg"
                    alt="Trustpilot 5 stars"
                    width={215}
                    height={100}
                  />
                </LeaderTrustpilotLogoLink>
                <LeaderTrustpilotText>
                  We’re rated 5 stars on Trustpilot, check out our{" "}
                  <a
                    href="https://uk.trustpilot.com/review/ecologi.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View our Trustpilot reviews"
                  >
                    reviews
                  </a>
                  .
                </LeaderTrustpilotText>
              </>
            )}
          </LeaderButtons>
        </Wrapper>
      </Leader>
    </Fragment>
  );
};
