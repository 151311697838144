import React from "react";
import { useRouter } from "next/router";
import {
  stringReplaceVariables,
  weeklyCostB2c,
  monthlyCostB2c,
} from "@ecologi/shared";
import { HomePageHero as Component } from "@ecologi/react-components/src/HomePageHero";
import { useStore } from "../../store";

export default function HomePageHero({ data }: any) {
  const { location } = useStore();
  const router = useRouter();

  const localWeeklyCostB2c = location
    ? weeklyCostB2c[location.countryCode]
    : weeklyCostB2c.US;

  const localMonthlyCostB2c = location
    ? monthlyCostB2c[location.countryCode]
    : monthlyCostB2c.US;

  const heroSubtext = data.text_2.replace(
    stringReplaceVariables.localWeeklyCostB2c,
    localWeeklyCostB2c
  );

  const leaderText = data.text_3.replace(
    stringReplaceVariables.localMonthlyCostB2c,
    localMonthlyCostB2c
  );

  return (
    <Component
      {...data}
      heroText={data.text_1}
      heroSubtext={heroSubtext}
      heroCTAText={data.button_1_label}
      onClickCTA={() => router.push("/plan")}
      onClickBusinessLink={() => router.push("/")}
      leaderText={leaderText}
    />
  );
}
