import styled from "@emotion/styled";
import { Link as ScrollLink } from "react-scroll";

export const Hero = styled.section`
  align-items: center;
  background: ${(props) => props.theme.colors.charcoal};
  background-image: url(/images/app/homepage-hero-bg.png);
  background-position: top;
  background-repeat: repeat-x;
  background-size: contain;
  color: ${(props) => props.theme.colors.mushroom};
  display: flex;
  justify-content: center;
  min-height: calc(100vh + 40px);
  padding-top: 120px;
  position: relative;
`;

export const HeroContent = styled.div`
  padding: 0 20px 50px;
  text-align: center;

  @media ${(props) => props.theme.bp.mobileUp} {
    max-width: 82vw;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    max-width: 900px;
  }
`;

export const HeroText = styled.h1`
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 1.3em;

  @media only screen and (min-width: 360px) {
    font-size: 36px;
    margin-bottom: 20px;
  }

  @media ${(props) => props.theme.bp.mobileUp} {
    font-size: 44px;
    margin-bottom: 30px;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 58px;
  }
`;

export const HeroSubtext = styled.h2`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 1.3em;

  @media only screen and (min-width: 360px) {
    font-size: 23px;
  }

  @media ${(props) => props.theme.bp.mobileUp} {
    font-size: 26px;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 30px;
  }
`;

export const HeroCta = styled.div`
  margin-bottom: 20px;

  @media only screen and (min-width: 360px) {
    margin-bottom: 60px;
  }
`;

export const HeroCtaBusinessLink = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: ${(props) => props.theme.colors.snowdrop};
  display: inline-block;
  margin-top: 20px;
  padding-bottom: 2px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    border-bottom-color: ${(props) => props.theme.colors.snowdrop};
  }
`;

export const HeroScrollButton = styled(ScrollLink)`
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='512' height='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M-1-1h582v402H-1z'/%3E%3Cg%3E%3Cpath fill='%23fff' d='M256 298.3l174.2-167.2c4.3-4.2 11.4-4.1 15.8.2l30.6 29.9c4.4 4.3 4.5 11.3.2 15.5L264.1 380.9c-2.2 2.2-5.2 3.2-8.1 3-3 .1-5.9-.9-8.1-3L35.2 176.7c-4.3-4.2-4.2-11.2.2-15.5L66 131.3c4.4-4.3 11.5-4.4 15.8-.2L256 298.3z'/%3E%3C/g%3E%3C/svg%3E");
  background-position: 50%;
  background-size: 100%;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  margin-bottom: 40px;
  opacity: 0.4;
  transition: opacity 0.3s;
  width: 40px;

  &:hover {
    opacity: 0.8;
  }
`;

export const Leader = styled.section`
  background: ${(props) => props.theme.colors.mushroom};
  position: relative;
  padding-top: 125px;

  &::before {
    background-image: url(/images/app/mountains-background.svg);
    background-repeat: repeat-x;
    background-size: 1600px;
    background-position: center;
    content: "";
    height: 350px;
    position: absolute;
    top: -250px;
    width: 100%;

    @media ${({ theme }) => theme.bp.mobileDown} {
      background-size: 1200px;
    }

    @media ${({ theme }) => theme.bp.desktopUp} {
      top: -300px;
    }
  }

  &::after {
    background-color: rgb(10, 97, 72);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='1600.039' height='126.834' viewBox='0 0 1601 126.91'%3E%3Cpath data-name='cream hill copy' d='M0 7.91s14.9-1.56 292 4 479.24 82.06 635 48 276.72-65.76 460-59c191.33 7.06 214 7 214 7v119H0v-119z' fill='%23e9e4dd' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-size: 1000px;
    content: "";
    height: 60px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 100%;
  }
`;

export const LeaderCta = styled.h3`
  font-size: 24px;
  font-weight: 300;
  line-height: 1.3;
  margin: 0 auto 40px;
  max-width: 600px;
  text-align: center;

  @media ${(props) => props.theme.bp.mobileUp} {
    font-size: 30px;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 34px;
  }
`;

export const LeaderButtons = styled.div`
  text-align: center;
  margin: 0;
  padding-bottom: 80px;
`;

export const LeaderDivider = styled.div`
  background: ${(props) => props.theme.colors.darkCream};
  height: 3px;
  margin: 40px auto;
  width: 80%;
`;

export const LeaderTrustpilotLogoLink = styled.a`
  display: block;
  height: 93px;
  margin: 0 auto 30px;
  width: 215px;

  img {
    width: 100%;
  }
`;

export const LeaderTrustpilotText = styled.p`
  font-weight: 300;
  padding: 0 10px;
  line-height: 1.4;

  a {
    color: inherit;

    &:hover {
      opacity: 0.6;
    }
  }
`;
