import React, { FC } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Leaf from "@ecologi/assets/icons/HeroIcons/icon_leaf.svg";
import Footprint from "@ecologi/assets/icons/HeroIcons/icon_footprint.svg";
import Carbon from "@ecologi/assets/icons/HeroIcons/icon_carbon.svg";
import Truck from "@ecologi/assets/icons/HeroIcons/icon_truck.svg";
import ClimateSolutions from "@ecologi/assets/icons/HeroIcons/icon_projects.svg";
import Tree from "@ecologi/assets/icons/HeroIcons/icon_trees.svg";
import Megaphone from "@ecologi/assets/icons/HeroIcons/megaphone.svg";
import { excludePropsFromForwarding } from "../utils";

export enum HeroIconTypes {
  LEAF = "LEAF",
  CARBON = "CARBON",
  FOOTPRINT = "FOOTPRINT",
  TRUCK = "TRUCK",
  CLIMATESOLUTIONS = "CLIMATE SOLUTIONS",
  TREE = "TREE",
  MEGAPHONE = "MEGAPHONE",
}

const wrapIcon = (Icon: React.FunctionComponent, width: number = 80) =>
  styled(Icon, excludePropsFromForwarding("isLight"))<HeroIconProps>`
    height: auto;
    width: ${width}px;
    fill: ${(props) => props.theme.colors.charcoal};

    margin-bottom: 15px;

    path {
      ${(props) =>
        props.isLight &&
        css`
          stroke: ${props.theme.colors.snowdrop};
          fill: white;
        `};
    }
  `;

const iconMapper: { readonly [type in HeroIconTypes]: FC<any> } = {
  [HeroIconTypes.LEAF]: wrapIcon(Leaf),
  [HeroIconTypes.CARBON]: wrapIcon(Carbon, 90),
  [HeroIconTypes.FOOTPRINT]: wrapIcon(Footprint),
  [HeroIconTypes.TRUCK]: wrapIcon(Truck),
  [HeroIconTypes.CLIMATESOLUTIONS]: wrapIcon(ClimateSolutions),
  [HeroIconTypes.TREE]: wrapIcon(Tree),
  [HeroIconTypes.MEGAPHONE]: wrapIcon(Megaphone),
};

export type HeroIconProps = {
  icon: HeroIconTypes;
  isLight?: boolean;
};

export const HeroIcon = ({
  icon = HeroIconTypes.LEAF,

  ...props
}: HeroIconProps) => {
  const Icon = iconMapper[icon] || iconMapper[HeroIconTypes.LEAF];
  return <Icon {...props} />;
};
