import React from "react";
import { useRouter } from "next/router";
import { BusinessPageHero as Component } from "@ecologi/react-components/src/BusinessPageHero";
import {
  stringReplaceVariables,
  monthlyCostB2b,
  putCommasIntoNumber,
} from "@ecologi/shared";
import { useTotals, parseQueryObject } from "../../lib";
import { useStore } from "../../store";

type Props = {
  data: any;
};

export default function BusinessPageHero({ data }: Props) {
  const router = useRouter();
  const { location } = useStore();
  // See if there is any business pre-populated data and decode it
  const { p: prepopulatedDataEncoded } = parseQueryObject(router.query);
  const prepopulatedData = prepopulatedDataEncoded
    ? atob(prepopulatedDataEncoded)
    : null;

  const { data: countData, error } = useTotals();

  const localMonthlyCostB2b = location
    ? monthlyCostB2b[location.countryCode]
    : monthlyCostB2b.US;

  const heroPriceInfo = data.text_3
    .replace(stringReplaceVariables.localMonthlyCostB2b, localMonthlyCostB2b)
    .replace(
      stringReplaceVariables.businessCount,
      countData && !error
        ? putCommasIntoNumber(
            countData.totalBusiness + countData.totalImpactOnly
          )
        : "thousands of"
    );

  return (
    <Component
      countryCode={location?.countryCode || "US"}
      data={data}
      heroPriceInfo={heroPriceInfo}
      prepopulatedData={prepopulatedData}
      onClickCTA={() => router.push("/business/checkout")}
    />
  );
}
