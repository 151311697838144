import React, { Fragment, useState } from "react";
import { putCommasIntoNumber, roundTo, Country } from "@ecologi/shared";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { ThreeColExplainer } from "../ThreeColExplainer";
import { EmployeeInput } from "./EmployeeInput";
import {
  PageWrapper,
  VideoBgOverlay,
  VideoBg,
  Content,
  HeroTitle,
  HeroSubtitle,
  HeroPriceInfo,
  Form,
  ImpactLabel,
  InputLabel,
  CtaNumberBlock,
  CtaNumbers,
  CtaNumber,
  CtaNumberType,
  CtaNumberEvery,
  CtaTitle,
  MountainsBackground,
  HillsContent,
  Leader,
  LeaderButtons,
} from "./components";
import { theme } from "../theme";
import { calcImpact } from "./utils";
import { ImpactPeriod } from "@ecologi/shared";

type Props = {
  countryCode: Country;
  data: any;
  heroPriceInfo: string;
  onClickCTA: any;
  prepopulatedData?: any;
};

export const BusinessPageHero = ({
  countryCode,
  data,
  heroPriceInfo,
  onClickCTA,
  prepopulatedData,
}: Props) => {
  // See if there is any business pre-populated data and decode it
  const [prepopulatedBusinessName, prepopulatedNumEmployees] = prepopulatedData
    ? prepopulatedData.split(",")
    : [null, null];

  const [numEmployees, setNumEmployees] = useState(
    prepopulatedNumEmployees ? prepopulatedNumEmployees : "10"
  );

  const numOfEmployees = parseInt(numEmployees);
  const period: ImpactPeriod = "month";

  const { trees, tonnes } = calcImpact({
    countryCode,
    numOfEmployees,
    period,
  });

  return (
    <div style={{ backgroundColor: theme.colors.mushroom }}>
      {data.text_1 && (
        <PageWrapper>
          <VideoBgOverlay>
            <VideoBg autoPlay muted loop playsInline>
              <source
                src="https://offset-earth-wp-assets.s3-eu-west-1.amazonaws.com/hero-video.mp4"
                type="video/mp4"
              />
            </VideoBg>
          </VideoBgOverlay>
          <Content>
            <Wrapper>
              {prepopulatedBusinessName ? (
                <HeroTitle>
                  {prepopulatedBusinessName} is capable of{" "}
                  <strong>massive</strong> impact
                </HeroTitle>
              ) : (
                <HeroTitle dangerouslySetInnerHTML={{ __html: data.text_1 }} />
              )}
              <HeroSubtitle dangerouslySetInnerHTML={{ __html: data.text_2 }} />
              {data.button_1_label && (
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={onClickCTA}
                    primary
                    text={data.button_1_label}
                  />
                </div>
              )}
              <HeroPriceInfo hasPaddingTop={!!data.text_4}>
                {heroPriceInfo}
              </HeroPriceInfo>
            </Wrapper>
          </Content>
        </PageWrapper>
      )}
      {data.text_4 && (
        <>
          <Wrapper style={{ top: "50px" }} isLarge>
            <Form>
              <InputLabel htmlFor="numEmployees">{data.text_4}</InputLabel>
              <EmployeeInput
                numEmployees={numEmployees}
                setNumEmployees={setNumEmployees}
              />
              <ImpactLabel>Your potential climate action:</ImpactLabel>
            </Form>
            <CtaNumbers>
              <CtaNumberBlock>
                <CtaNumber data-testid="trees">
                  {putCommasIntoNumber(trees)}
                </CtaNumber>
                <CtaNumberType>Trees funded</CtaNumberType>
                <CtaNumberEvery>Every {period}</CtaNumberEvery>
              </CtaNumberBlock>
              <CtaNumberBlock isCarbon>
                <CtaNumber data-testid="carbon">
                  {putCommasIntoNumber(roundTo(2)(tonnes))}
                </CtaNumber>
                <CtaNumberType>
                  Tonnes of CO<sub>2</sub>e
                </CtaNumberType>
                <CtaNumberEvery>Avoided every {period}</CtaNumberEvery>
              </CtaNumberBlock>
            </CtaNumbers>
          </Wrapper>
          <MountainsBackground>
            <HillsContent>
              <Wrapper>
                <Fragment>
                  <CtaTitle>{data.explainer_title}</CtaTitle>
                </Fragment>
              </Wrapper>
            </HillsContent>
          </MountainsBackground>
          <Leader id="leader">
            <Wrapper isExtraLarge>
              <ThreeColExplainer
                columnData={data.explainer}
                columnKeys={["Flags", "Tablet", "People"]}
              />
              <LeaderButtons>
                <Button
                  primary
                  text={data.button_1_label}
                  onClick={onClickCTA}
                />
                <p>
                  More than 20 employees?{" "}
                  <a href="/business/business-signup" className="link">
                    Get in touch
                  </a>
                </p>
              </LeaderButtons>
            </Wrapper>
          </Leader>
        </>
      )}
    </div>
  );
};
