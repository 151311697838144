import {
  Hero,
  HeroOffsetText,
  HeroVariantProps,
} from "@ecologi/react-components/src/Hero";

export enum HeroTypes {
  DEFAULT = "DEFAULT",
  OFFSET_TOP = "OFFSET_TOP",
}

const HeroTypeMapper = {
  [HeroTypes.DEFAULT]: Hero,
  [HeroTypes.OFFSET_TOP]: HeroOffsetText,
};

type Props = {
  data: HeroVariantProps & {
    hero_type: HeroTypes;
  };
};

export default function HeroWidget(props: Props) {
  const HeroComponent = HeroTypeMapper[props.data.hero_type] || Hero;
  return <HeroComponent {...props.data} subtitle={props.data.subtitle || ""} />;
}
