import React, { Fragment, useState } from "react";
import styled from "@emotion/styled";
import Head from "next/head";
import Image from "next/legacy/image";
import { Button } from "../Button";
import { theme } from "../theme";
import { TrustPilotTrustBox } from "../TrustPilotTrustBox";
import { LeaderTrustpilotLogoLink } from "../HomePageHero/components";
import { useHLS } from "../utils/use-hls";
import { Typography } from "../Typography";
import { putCommasIntoNumber, User } from "@ecologi/shared";
import { isImpactShop } from "../utils/is-impact-shop";
import { css } from "@emotion/react";
import { isZeroRoadmap } from "../utils/is-zero-roadmap";
import { getResizedImageURL } from "../utils";

interface VideoHeroButton {
  text: string;
  link: string;
}

type VideoHeroProps = {
  backgroundImageSrc: string;
  buttons?: VideoHeroButton[];
  isBusinessDataVisible: boolean;
  numBusinesses?: number;
  subtitle: string;
  title: string;
  videoSrc?: string;
  slug: string | string[];
  user: User;
  hasLargerTitle: boolean;
};

const HeroWrapper = styled.div`
  position: relative;
`;

const BackgroundWrapper = styled.div<{
  backgroundImageMobile: string;
  backgroundImageDesktop: string;
  isImpactPage?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.night};
  background-image: url(${(props) => props.backgroundImageMobile});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 850px;

  @media ${({ theme }) => theme.bp.tabletUp} {
    min-height: ${(props) => (props?.isImpactPage ? "100vh" : "750px")};
    background-image: url(${(props) => props.backgroundImageDesktop});
  }

  &:after {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  video {
    display: none;
    height: 100vh;
    min-height: 750px;
    object-fit: cover;
    width: 100%;

    @media ${({ theme }) => theme.bp.tabletUp} {
      display: block;
    }
  }
`;

const zeroVariants = {
  roadmap: css`
    bottom: 4vh;
    padding: 0 5px;
  `,
};

const TextWrapper = styled.div<{ zeroRoadmap: string }>`
  bottom: 14vh;
  left: 50%;
  max-width: 936px;
  padding: 0 15px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;

  @media ${({ theme }) => theme.bp.mobileUp} {
    padding: 0 30px;
  }

  @media ${({ theme }) => theme.bp.tabletUp} {
    bottom: 100px;
  }
  ${(props) => zeroVariants[props.zeroRoadmap]}
`;

const Title = styled(Typography)<{
  isImpactPage: boolean;
  hasLargerTitle: boolean;
}>`
  color: ${({ theme }) => theme.colors.snowdrop};
  font-weight: 300 !important;
  margin-bottom: 25px;
  letter-spacing: ${(props) =>
    props.hasLargerTitle ? "6.3px !important" : "normal"};
  font-size: ${(props) => (props.hasLargerTitle ? "80px !important" : "")};
  line-height: ${(props) => (props.hasLargerTitle ? "88px !important" : "")};

  strong {
    font-weight: 700;
  }

  @media ${({ theme }) => theme.bp.mobileDown} {
    font-size: ${(props) =>
      props.hasLargerTitle ? "56px !important" : "36px"};
    padding: ${(props) => (props?.isImpactPage ? "0 10px" : "")};
    letter-spacing: ${(props) =>
      props.hasLargerTitle ? "5.3px !important" : "normal"};
    line-height: ${(props) => (props.hasLargerTitle ? "64px !important" : "")};
  }
`;

const SubtitleWrapper = styled.div`
  margin-bottom: 40px;
`;

const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.snowdrop};
  margin-bottom: 20px;
  padding: 0 20px;

  @media ${({ theme }) => theme.bp.mobileDown} {
    font-size: 18px;
  }
`;

const Images = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
`;

const FirstImageWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.bp.tabletUp} {
    display: block;
  }
`;

const Underline = styled("a")`
  color: white;
`;

const SecondImageWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.bp.mobileUp} {
    display: block;
  }
`;

const BusinessData = styled(Typography)<{ isImpactShop?: boolean }>`
  color: ${({ theme }) => theme.colors.snowdrop};
  display: none;
  font-weight: 400 !important;
  margin-bottom: ${(props) => (props?.isImpactShop ? "64px" : "38px")};

  @media ${({ theme }) => theme.bp.smallMobileUp} {
    display: block;
  }
`;

const Buttons = styled.div<{ isImpactPage?: boolean }>`
  margin-bottom: ${(props) => (props?.isImpactPage ? 24 : 32)}px;

  // Non-first button needs to shift
  a + a {
    margin-left: 20px;

    @media ${({ theme }) => theme.bp.tabletUp} {
      margin-left: 24px;
    }
  }
`;

export const VideoHero = ({
  backgroundImageSrc,
  buttons,
  hasLargerTitle,
  isBusinessDataVisible,
  numBusinesses,
  slug,
  subtitle,
  title,
  user,
  videoSrc,
}: VideoHeroProps) => {
  const [useFallbackTrustPilot, setUseFallbackTrustPilot] = useState(true);
  const [videoRef] = useHLS({ src: videoSrc });
  const backgroundImageMobile =
    getResizedImageURL(backgroundImageSrc, 1000) || "";
  const backgroundImageDesktop =
    getResizedImageURL(backgroundImageSrc, 2000) || "";

  return (
    <Fragment>
      <Head>
        <link
          rel="preload"
          fetchPriority="high"
          as="image"
          href={backgroundImageMobile}
        />
        <link
          rel="preload"
          fetchPriority="high"
          as="image"
          href={backgroundImageDesktop}
        />
      </Head>
      <HeroWrapper>
        <BackgroundWrapper
          backgroundImageMobile={backgroundImageMobile}
          backgroundImageDesktop={backgroundImageDesktop}
          isImpactPage={isImpactShop(slug)}
        >
          {videoSrc && !isImpactShop(slug) && (
            <video ref={videoRef} autoPlay loop muted playsInline />
          )}
        </BackgroundWrapper>
        <TextWrapper zeroRoadmap={isZeroRoadmap(slug) ? "roadmap" : ""}>
          <Title
            as="h1"
            use="heroTitle"
            dangerouslySetInnerHTML={{ __html: title }}
            isImpactPage={isImpactShop(slug)}
            hasLargerTitle={hasLargerTitle}
          />

          <SubtitleWrapper>
            <Subtitle as="h2" use="subtitle2">
              {subtitle}
            </Subtitle>
            {isBusinessDataVisible && !isImpactShop(slug) && (
              <BusinessData as="h3" use="body2">
                Join{" "}
                <strong>
                  {numBusinesses
                    ? putCommasIntoNumber(numBusinesses)
                    : "12,000+"}
                </strong>{" "}
                businesses that are taking climate action.
              </BusinessData>
            )}
          </SubtitleWrapper>

          {buttons && (
            <Buttons isImpactPage={isImpactShop(slug)}>
              {buttons.map((button, idx) => (
                <Button
                  text={
                    isImpactShop(slug) && user ? "Buy impact now" : button.text
                  }
                  href={
                    isImpactShop(slug) && user ? "/impact-shop" : button.link
                  }
                  key={idx}
                  primary
                />
              ))}
            </Buttons>
          )}

          {isImpactShop(slug) && (
            <BusinessData as="h4" use="body2" isImpactShop>
              {user ? (
                <>
                  You are logged in as{" "}
                  <Underline href={"/profile"}>{user.username}</Underline>
                </>
              ) : (
                <>
                  Existing customers{" "}
                  <Underline href={"/api/login"}>Sign in</Underline>
                </>
              )}
            </BusinessData>
          )}

          <Images>
            <FirstImageWrapper>
              <Image
                src="/images/app/eco-restoration.png"
                alt="United Nations Decade on Ecosystems Restoration Partner"
                width={230}
                height={82}
              />
            </FirstImageWrapper>
            <SecondImageWrapper>
              <Image
                src="/images/app/b-corp.png"
                alt="Certified B-Corp"
                width={92}
                height={140}
              />
            </SecondImageWrapper>
            {/* Will not be visible until loaded */}
            <TrustPilotTrustBox
              backgroundColor={theme.colors.mushroom}
              height="103"
              onErrored={() => setUseFallbackTrustPilot(true)}
              onLoaded={() => setUseFallbackTrustPilot(false)}
              templateId="53aa8807dec7e10d38f59f32"
              theme="dark"
              width="220"
            />

            {/* Fallback if not yet loaded - case of Adblockers / script failed to load */}
            {useFallbackTrustPilot && (
              <div>
                <LeaderTrustpilotLogoLink
                  href="https://uk.trustpilot.com/review/ecologi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="View our Trustpilot reviews"
                >
                  <Image
                    src="/images/app/trustpilot-light.svg"
                    alt="Trustpilot 5 stars"
                    width={220}
                    height={103}
                  />
                </LeaderTrustpilotLogoLink>
              </div>
            )}
          </Images>
        </TextWrapper>
      </HeroWrapper>
    </Fragment>
  );
};
